<template>
  <div class="intro">
    <div class="viewport">
      <div class="step-title">
        <div v-html="title"></div>
      </div>
      <div class="step-number">A</div>
      <div>
        跳转后，点击 【接受】<br/>
        如果看不到以下页面，请 <a class="retry" @click="toTestflight">重试</a><br>
        <img class="step-screenshot" style="min-height: 5rem" src="@/assets/images/FAQ-A.jpg">
      </div>

      <div class="step-number">B</div>
      <div>
        <p>
          如显示 “已移除测试人员” 请勿担心<br>
          并不影响使用，下次安装会重新加入
        </p>
        <img class="step-screenshot" style="min-height: 5rem" src="@/assets/images/FAQ-B.jpg">
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { useStore } from "vuex"
  import {getDomain} from "@/config";

  export default {
    name: 'IOSTestflightFaq',
    components: {},
    setup () {
      let useStores = useStore()
      let store = useStores.state
      let testflightUrl = ref('')

      function toTestflight () {
        window.location.href = testflightUrl.value
      }

      function setUrl () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/ios-url`)
          .then((date) => {
            testflightUrl.value = date.date.obj['ios-testflight-url']
          })
          .catch((e) => {
            console.log(e)
          })
      }

      let countDownNum = ref(3)
      let title = ref('TestFlight&nbsp;问题解答')

      function countDownToTestFlight (url) {
        let countDownInterval = setInterval(function () {
          if (countDownNum.value === 1) {
            window.location.href = url
            window.clearInterval(countDownInterval)
          } else {
            countDownNum.value--
            title.value = `即将跳转 &nbsp;&nbsp;(<span id="count-down">${countDownNum.value}</span>)`
          }
        }, 1000)
      }
      if (store.toIosTestflightFaq) countDownToTestFlight(testflightUrl.value)


      return {
        toTestflight,
        testflightUrl,
        setUrl,
        title,
        countDownNum
      }
    },
    created () {
      this.setUrl()
    }
  }
</script>

<style scoped>
  .intro, .intro > div {
    background-color: #a1eeff;
  }

  img {
    width: 80%;
    max-width: 600px;
    border: 1px solid;
    border-radius: 20px;
    margin: 1rem 0;
  }

  .retry {
    color: #06f;
  }
</style>
